$primary: #DC2321;
$secondary: #282828;
$tertiary: #FF9200;
$dark: $secondary;

$inactivo:#d85b25;
$experto:#b30e29;
$preferente:#9e9fa0;
$inactivo-deg:linear-gradient(#b8431b to #ec8d38);

$bronce:#b74b17;
$plata:#7c7c7d;
$oro:#d7a016;