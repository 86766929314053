$playerBlue: #261235;
$playerPink: #ed1e79;
$playerYellow: #fbb03b;

:root {
  --side-menu: #{rem(300px)};
  --header: #{rem(140px)};
  --headerDash: #{rem(100px)};
  @include break(xl-mw) {
    --header: #{rem(60px)};
    --headerDash: #{rem(60px)};
  }
}

body {
  overflow-x: hidden;
}

main {
  min-height: calc(100vh - var(--header));
}

.page {
  padding-top: var(--header);
}

[class*="__bg"] {
  pointer-events: none;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.text-upper {
  text-transform: uppercase;
}
.text-wide {
  font-weight: 800;
}

.section {
  &__title,
  &__subtitle {
    text-align: center;
  }
  &__title {
    margin: 0;
    margin-bottom: 0.2em;
    @include break(md-mw) {
      font-size: rem(30px);
    }
  }
  &__subtitle {
    font-size: rem(20px);
    @include break(md-mw) {
      font-size: rem(16px);
    }
  }
}

.interior {
  padding: rem(40px) 0;
  min-height: 50vh;
}

.mapa {
  height: 60vh;
  background: negro(0.05);
  margin-bottom: rem(40px);
  overflow: hidden;
  @include transicion;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 20px negro(0.1), 0 0 5px negro(0.1);
  border: rem(5px) solid blanco(1);
  @include break(md-mw) {
    // aspect-ratio: 3/4;
  }
  &__window {
    max-width: 500px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    h5 {
      font-size: rem(18px);
      text-transform: uppercase;
      font-weight: 800;
      margin-top: 0;
    }
  }
  &__image {
    overflow: hidden;
    aspect-ratio: 1/1;

    width: 40%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @include break(sm-mw) {
    &__window {
      flex-direction: column;
    }
    &__image {
      width: 100%;
      margin-bottom: rem(20px);
    }
  }
  &__text {
    line-height: 1.5;
  }
}

.preloader {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid $primario;
  background: url("https://mundoexperto.s3.us-west-2.amazonaws.com/assets/img/preloader.png")
    center center no-repeat;
  background-size: 80px;
  position: relative;
  &__container {
    padding: 20% 0;
    display: flex;
    justify-content: center;
  }
  &__child {
    @include posicion(absolute, 0, 0, 0, 0);
    width: 100%;
    height: 100%;
    animation: rotate 800ms ease infinite forwards;
    &::after {
      content: "";
      display: block;
      margin: rem(2px);
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 4px solid $primario;
      background: lighten($secundario, 50%);
    }
  }
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

// SWIPER CUSTOM
.swiper--custom {
  height: max-content;
  .swiper-wrapper {
    height: auto;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $secundario;
    color: blanco(1);
    &::after {
      font-size: rem(30px);
    }
    @include break(md-mw) {
      display: none;
    }
  }
  .swiper-slide {
    display: flex;
    flex-direction: column;
  }
  .swiper-button-prev {
    &::after {
      transform: translateX(-25%);
    }
  }
  .swiper-button-next {
    &::after {
      transform: translateX(25%);
    }
  }
  .swiper-pagination-bullet {
    background: $secundario;
    opacity: 1;
    width: rem(15px);
    height: rem(15px);
  }
  .swiper-pagination-bullet-active {
    background: blanco(1);
  }
}

// Monkey custom
.btn {
  text-transform: uppercase;
  font-size: rem(14px);
  font-weight: 800;
}

.form {
  [type="text"],
  [type="email"],
  [type="date"],
  [type="phone"],
  [type="password"],
  select,
  textarea {
    + label {
      margin-left: rem(15px);
      margin-bottom: rem(5px);
    }
  }
  label {
    color: $secundario;
    font-weight: 800;
    text-transform: uppercase;
  }
}

.content {
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-transform: uppercase;
    font-weight: 800;
  }
  h1 {
    font-size: 2.488rem;
  }

  h2 {
    font-size: 2.074rem;
  }

  h3 {
    font-size: 1.728rem;
  }

  h4 {
    font-size: 1.44rem;
  }

  h5 {
    font-size: 1.2rem;
  }
}

#tidio-chat iframe {
  bottom: rem(70px) !important;
  right: 0 !important;
}

.legal-terms {
  padding: rem(50px) 0;
  text-align: center;
  &__item {
    margin-bottom: rem(20px);
  }
  p {
    font-size: rem(12px);
    margin-bottom: 0.5em;
  }
}

.huincha {
  &__slider {
    .swiper-pagination-bullet-active {
      background: $secundario;
    }
  }
}
