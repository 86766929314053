.checks {
  $t : &;
  display: flex;
  flex-wrap: wrap;
  gap: rem(20px);
  input {
    display: none;
  }
  &__item {
    // margin-right: rem(20px);
    // margin-bottom: rem(20px);
    width: 100%;
    max-width: rem(280px);
    display: flex;
    align-items: center;
    padding: rem(12px) rem(10px);
    border: 1px solid negro(.05);
		border-radius: 30px;
		background: blanco(1);
		color: map-deep-get($_CONFIG, 'ui', 'colores', 'textos', 'inputs');
		position: relative;
		@include transicion;
    box-shadow: 0 0 rem(15px) negro(.1);
    cursor: pointer;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__icon {
    margin-bottom: rem(-5px);
    margin-top: rem(-5px);
    margin-right: rem(10px);
    svg {
      width: rem(25px);
      height: rem(25px);
    }
  }
  &__title {
    margin: 0 auto;
    font-weight: 800;
  }
  &__check {
    width: rem(15px);
    height: rem(15px);
    border-radius: rem(15px);
    border: 3px solid blanco(1);
    margin-left: rem(10px);
    box-shadow: 0 0 rem(10px) negro(.4);
  }
  input {
    &:checked {
      ~ #{$t}__check {
        background: $secundario;
      }
    }
  }
}
