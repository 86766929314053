.socios-estrategicos {
  padding: rem(100px) 0;
  &__grid {
    padding-top: rem(50px);
    display: flex;
    // display: grid;
    // grid-template-columns: repeat(10, 1fr);
    flex-wrap: wrap;
    // margin-left: rem(-5px);
    // margin-right: rem(-5px);
    gap: rem(15px);
    justify-content: center;
  }
  &__item {
    // width: calc(100% / 10 - 15px / 2);
    max-width: rem(150px);
    // padding: 0 rem(5px);
    // margin-bottom: rem(10px);
    img {
      display: block;
      width: 100%;
    }
    @include break(lg-mw) {
      width: calc(100% / 5);
    }
    @include break(md-mw) {
      width: calc(100% / 4);
    }
    @include break(sm-mw) {
      width: calc(100% / 3);
    }
  }
}