.breadcrumbs {
  // padding: 15px 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: rem(15px);
  // @include break(md) {
  //   padding: rem(15px) 0;
  // }
  color: $secundario;
  &__item {
    margin-right: rem(10px);
    font-size: rem(14px);
    // font-weight: 600;
  }
  &__link {
    text-decoration: none;
    color: currentColor;
  }
  &__current {
    opacity: .5;
  }
}