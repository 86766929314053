.temas-interes {
  $t: &;
  position: relative;
  padding: 40px 0;
  border-top: rem(20px) solid $terciario;
  &__bg {
    @include posicion(absolute, 0, 0, 0, 0);
    background: $secundario;
    img {
      opacity: 0.2;
    }
  }
  &__container {
    position: relative;
  }
  &__title {
    text-align: center;
    margin-top: 0;
  }
  &__grid {
    display: flex;
    // width: 100%;
    margin-left: rem(-$gap);
    margin-right: rem(-$gap);
    @include break(lg-mw) {
      flex-wrap: wrap;
    }
  }
  &__item {
    // flex: 1;
    width: 25%;
    @include transicion;
    padding: 0 rem(15px);
    border-right: 1px dashed lighten($secundario, 10%);
    margin-bottom: 30px;
    &:last-of-type {
      border: 0;
    }
    &__title {
      font-size: rem(25px);
    }
    &__desc {
      opacity: 0.6;
      margin-bottom: rem(30px);
    }
    @include break(lg-mw) {
      width: 50%;
      border: 0;
    }
    @include break(md-mw) {
      width: 100%;
    }
  }
  &__image {
    img {
      width: 100%;
      display: block;
    }
  }

  @include break(lg) {
    &__grid {
      min-height: 20vh;
      @include transicion;
      &:hover {
        min-height: 60vh;
        #{$t}__item {
          opacity: 0.5;
          width: calc(100% / 12 * 3.5);
        }
      }
    }
    #{$t}__item {
      &:hover {
        opacity: 1;
        width: calc(100% / 12 * 4.5);
      }
    }
  }
}
