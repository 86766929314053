.menu {
  $t: &;
  &__list {
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__item {
    padding: 0 rem(1px);
    width: max-content;
  }
  &__link {
    color: blanco(1);
    text-decoration: none;
    padding: rem(5px) rem(15px);
    text-transform: uppercase;
    @include transicion;
    border-radius: rem(20px);
    text-align: center;
    font-size: rem(14px);
    &.active,
    &:hover {
      background: lighten($secundario, 5%);
    }
  }
  &-top {
    &__list {
      display: flex;
    }
    &__link {
      display: flex;
      text-align: center;
      text-decoration: none;
      font-size: rem(11px);
      color: blanco(1);
      padding: rem(5px) rem(10px);
      text-transform: uppercase;
      svg {
        margin-right: rem(5px)
      }
    }
  }
  &-mobile {
    @include posicion(fixed, var(--header), 0, 0, null);
    z-index: 10;
    width: 100%;
    max-width: rem(400px);
    background: $secundario;
    @include transicion;
    transform: translateX(100%);
    padding: rem(40px) 0 rem(180px);
    overflow-y: auto;
    &--active {
      transform: translateX(0);
    }
    &__nav {
      margin-bottom: rem(20px);
    }
    &__link {
      display: flex;
      align-items: center;
      color: blanco(1);
      padding: rem(15px) rem(20px);
      text-decoration: none;
      @include transicion;
      text-transform: uppercase;
      &.active {
        background: blanco(.075);
      }
      &--1 {
        background: blanco(.05);
      }
      &--2 {
        background: $primario;
      }
      svg {
        margin-right: rem(10px);
      }
    }
  }
}
