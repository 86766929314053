/*!
* ¡ATENCIÓN! Hoja de estilos generada con SASS, no modificar.
*/

@charset "utf-8";

@import "./base/variables.scss";

// LIBRERIAS
@import "./libs/monkey\ 🐵/🐵";

// BASE
// @import "./base/fonts";
@import "./base/general";

// COMPONENTES
@import "./components/header";
@import "./components/rrss";
@import "./components/menu";
@import "./components/slider";
@import "./components/clientes";
@import "./components/descuento";
@import "./components/categorias";
@import "./components/temas-interes";
@import "./components/testimonios";
@import "./components/socios-estrategicos";
@import "./components/banner";
@import "./components/filtro-categorias";
@import "./components/articles";
@import "./components/login";
@import "./components/registro";
@import "./components/checks";
@import "./components/dashboard";
@import "./components/breadcrumbs";
@import "./components/card";
@import "./components/newsletter";
@import "./components/sidebar";
@import "./components/accordion";
@import "./components/tiendas";
@import "./components/responses";
@import "./components/footer";
@import "./components/fixed-btns";
@import "./components/error404";
@import "./components/popup";
@import "./components/players";
@import "./components/expert";
@import "./components/beneficios";
@import "./components/crop";
@import "./components/futbol";
@import "./components/statusbar";
@import "./components/darkmode";


