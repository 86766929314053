.players {
  margin-bottom: rem(100px);
  &__section {
    padding: rem(50px) 0;
    &--bg {
      background: negro(0.1);
    }
  }
  &__banner {
    position: relative;
    height: rem(400px);
    &__bg {
      overflow: hidden;
      @include posicion(absolute, 0, 0, 0, 0);
    }
    h1 {
      position: absolute;
      visibility: hidden;
    }
  }
  &__title {
    text-align: center;
    color: $playerBlue;
    margin-bottom: rem(30px);
  }
  &__subtitle {
    color: $primario;
    margin: 0;
  }
  &__separador {
    border-top: 2px dashed negro(.2);
    width: 80%;
    margin: rem(30px) auto;
  }
  &__select {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include break(sm-mw) {
      flex-direction: column;
    }
    &__input {
      margin: rem(10px);
      display: inline-flex;
      align-items: center;
    }
    &__label {
      background: desaturate(lighten($playerBlue, 50%), 30%);
      padding: rem(5px);
      height: rem(40px);
      display: flex;
      align-items: center;
    }
    &__arrow {
      width: rem(40px);
      height: rem(40px);
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primario;
      background: $playerBlue;
    }
  }
  &__dates {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: desaturate(lighten($primario, 42%), 20%);
    padding: rem(10px);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    margin-bottom: rem(50px);
    overflow: hidden;
    max-height: 0;
    transition: all ease 400ms;
    &__item {
      width: rem(60px);
      height: rem(60px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(30px);
      cursor: pointer;
      color: $playerBlue;
      background: transparent;
      @include transicion;
      &:hover {
        background: desaturate(lighten($primario, 30%), 30%);
      }
      &--active {
        color: $primario;
      }
    }
    &--visible {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      max-height: rem(1000px);
    }
  }
  &__grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: rem(15px);
  }
  &__card {
    position: relative;
    width: 300px;
    padding: 0 rem(30px);
    &__avatar {
      clip-path: url(#player);
      overflow: hidden;
      width: 240px;
      height: 340px;
      margin: auto;
      img {
        display: block;
        margin: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: rem(150px);
        background: linear-gradient(transparent, negro(0.9));
        @include posicion(absolute, null, 0, 0, 0);
      }
    }
    &__frame {
      @include posicion(absolute, 0, 0, 0, 0);
      width: 100%;
      height: 100%;
      &--1 {
        fill: blanco(1);
      }
      &--2 {
        fill: $playerBlue;
      }
      &--3 {
        fill: $primario;
      }
    }
    &__logos {
      @include posicion(absolute, rem(60px), null, null, rem(60px));
      width: rem(40px);
      img {
        display: block;
        width: 100%;
        margin-bottom: rem(10px);
      }
    }
    &__info {
      @include posicion(absolute, null, 0, rem(53px), 0);
      text-align: center;
    }
    &__ribbon {
      background: $playerBlue;
      padding: rem(10px);
      position: relative;
      &::after,
      &::before {
        content: "";
        display: block;
        width: rem(30px);
        height: rem(20px);
        transform: scaleY(0.5);
        transform-origin: top;
        border: rem(15px) solid transparent;
        @include posicion(absolute, 100%, null, null, null);
      }
      &::before {
        border-right-color: darken($primario, 30%);
        border-top-color: darken($primario, 30%);
        left: 0;
      }
      &::after {
        border-left-color: darken($primario, 30%);
        border-top-color: darken($primario, 30%);
        right: 0;
      }
    }
    &__name {
      font-size: rem(16px);
      color: blanco(1);
      text-transform: uppercase;
      font-weight: 800;
    }
    &__title {
      text-transform: uppercase;
      font-size: rem(11px);
      color: $playerYellow;
    }
    &__team {
      padding: rem(5px) 0;
      img {
        width: 40px;
        height: 40px;
        display: block;
        margin: auto;
      }
    }
  }
  &__carousel {
    width: 100%;
    &__container {
      max-width: rem(400px);
      display: flex;
      height: 100%;
      justify-content: flex-end;
      flex-direction: column;
    }
    &__item {
      padding: rem(30px) 0 rem(50px);
    }
    &__logos {
      display: flex;
      position: relative;
      img {
        width: 80px;
      }
    }
    &__name {
      padding: rem(15px);
      background: $primario;
      text-align: center;
      line-height: 1;
      margin: 0;
    }
    &__date {
      padding: rem(5px);
      background: $playerBlue;
      text-align: center;
      font-size: rem(14px);
      span {
        color: $primario;
        font-weight: 600;
      }
    }
    .swiper-pagination {
      text-align: left;
      bottom: rem(20px);
      .swiper-pagination-bullet {
        border: 2px solid $playerBlue;
        background: blanco(1);
        opacity: 1;
        width: rem(12px);
        height: rem(12px);
        &.swiper-pagination-bullet-active {
          background-color: $primario;
        }
      }
    }
  }
}
