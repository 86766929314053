.fixed-btns {
  @include posicion(fixed,null,0,0,null);
  z-index: 99;
  display: flex;
  gap: rem(15px);
  align-items: center;
  padding: rem(15px);
  &__item {
    animation: agrandar ease 200ms 1 forwards;
    margin: rem(5px) 0;
    .btn--circulo {
      width: rem(60px);
      height: rem(60px);
      max-width: rem(60px);
    }
  }
}

@keyframes agrandar {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}