.testimonios {
  $t: &;
  position: relative;
  padding: 50px 0;

  &__bg {
    @include posicion(absolute, 0, 0, 40%, 0);
    background: blanco(1);
    img {
      opacity: 0.2;
    }
  }
  &__container {
    position: relative;
  }
  &__info {
    text-align: center;
    margin-bottom: rem(30px);
  }
  &__title {
    margin: 0;
    margin-bottom: 0.2em;
  }
  &__subtitle {
    font-size: rem(25px);
  }
  &__carousel {
    position: relative;
    // padding-bottom: rem(30px);
    .swiper-button-prev {
      left: rem(-40px);
    }
    .swiper-button-next {
      right: rem(-40px);
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: $secundario;
    border-top: 20px solid $primario;
    padding: 0 rem(30px) rem(30px);
    // margin-top: rem(20px);
    &__bg {
      @include posicion(absolute, 0, 0, 0, 0);
      img {
        opacity: 0.2;
      }
    }
    &__content {
      position: relative;
    }
    @include break(md-mw) {
      padding: 0 rem(15px) rem(15px);
    }
  }
  &__image {
    width: 160px;
    height: 160px;
    display: block;
    margin: rem(-10px) auto rem(20px);
    img {
      border: rem(10px) solid $secundario;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &__name {
    font-size: rem(16px);
    display: inline-flex;
    padding: rem(5px) rem(20px);
    background: $secundario;
    border-radius: rem(30px);
    margin: 0;
    margin-bottom: rem(10px);
  }
  &__cargo,
  &__meta__item {
    display: inline-flex;
    color: blanco(0.6);
    font-size: rem(12px);
    margin: 0;
    margin-bottom: rem(10px);
    background: negro(.05);
    padding: rem(3px) rem(5px);
    border-radius: rem(15px);
  }
  &__meta {
    display: flex;
    justify-content: center;
    &__item {
      margin: 0 rem(5px);
      margin-bottom: rem(20px);
      svg {
        margin-right: rem(10px);
      }
    }
  }
  &__desc {
    color: blanco(0.6);
    display: flex;
    text-align: justify;
    font-size: rem(14px);
    svg,
    path,
    rect {
      fill: $primario;
    }
    svg {
      margin: 0 rem(5px);
      &:last-of-type {
        align-self: flex-end;
      }
    }
  }
  .swiper--custom {
    .swiper-container {
      padding-bottom: rem(30px);
    }
    .swiper-pagination {
      bottom: auto;
      top: calc(100% - 30px);
      // position: relative;
      padding: rem(10px) 0;
    }
    .swiper-pagination-bullet-active {
      background: rgba($secundario, 0.3);
    }
  }
}
