.newsletter {
  background: lighten($secundario, 80%);
  padding: rem(50px) 0;
  margin-bottom: rem(50px);
  .single & {
    margin-top: var(--header);
  }
  &__form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include break(lg-mw) {
      flex-direction: column;
    }
  }
  &__item {
    // flex: 1;
    width: calc(100% / 2 - 20px);
    @include break(lg-mw) {
      width: 100%;
    }
    &--info {
      text-align: right;
      opacity: 0.7;
      @include break(lg-mw) {
        text-align: center;
        margin-bottom: rem(30px);
      }
    }
    &--inputs {
      display: flex;
      flex-wrap: wrap;
      .alerta {
        margin: 0;
        margin-top: rem(5px);
        max-width: rem(365px);
      }
      @include break(lg-mw) {
        justify-content: center;
      }
    }
  }
  &__title {
    font-size: rem(20px);
  }
  &__input {
    width: 100%;
    max-width: rem(300px);
    border-radius: rem(30px);
    padding: rem(12px) rem(20px);
    padding-right: rem(45px);
    margin-right: rem(-35px);
    border: 1px solid negro(0.1);
    box-shadow: 0 0 rem(15px) negro(0.05);
    background: blanco(1);
    color: map-deep-get($_CONFIG, 'ui', 'colores', 'textos', 'inputs');
    // position: relative;
    // z-index: 1;
    @include transicion;
    appearance: none;
    &:focus {
      outline: 0;
    }
    &::placeholder {
      opacity: .6;
    }
  }
}
