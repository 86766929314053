.categorias {
  $t: &;
  position: relative;
  padding-bottom: rem(20px);
  &__bg {
    @include posicion(absolute, 0, 0, 20%, 0);
    background: $secundario;
    img {
      opacity: 0.2;
    }
  }
  &__info {
    position: relative;
    padding: rem(30px) 0;
    text-align: center;
    max-width: rem(1000px);
    margin: auto;
  }
  &__title {
    margin-top: 0;
    margin-bottom: 0.2em;
  }
  &__item {
    position: relative;
    display: block;
    margin-bottom: rem(30px);
    // padding-bottom: 75%;
    background: $secundario;
    overflow: hidden;
    &__container {
      position: relative;
    }
    &__bg {
      // @include posicion(absolute, 0, 0, 0, 0);
      // aspect-ratio: 4/3;
      overflow: hidden;
      img {
        opacity: 0.5;
        height: auto;
        object-fit: initial;
        margin-bottom: 0;
      }
      @include break(md-mw) {
        // aspect-ratio: 16/9;
      }
    }
    &__info {
      @include posicion(absolute, null, null, 0, 0);
      width: 100%;
      &__label {
        padding: rem(5px) rem(10px);
        display: flex;
        align-items: center;
        width: 100%;
        max-width: rem(240px);
        background: $primario;
        color: blanco(1);
        @include break(md-mw) {
          padding: rem(10px) rem(15px);
        }
      }
    }
    &__dsctos {
      display: inline-flex;
      background: $secundario;
    }
    &__dscto {
      display: flex;
      flex-direction: column;
      padding: rem(5px) rem(10px);
      text-align: center;
      font-size: rem(26px);
      font-weight: 800;
      color: blanco(1);
      text-shadow: 0 0 10px negro(.5);
      position: relative;
      span {
        font-size: rem(10px);
        color: blanco(1);
        font-weight: normal;
      }
      &:not(:first-child) {
        &::after {
          content: '';
          width: 1px;
          height: 80%;
          background: blanco(.2);
          @include posicion(absolute,10%,null,10%,0)
        }
      }
      @include break(md-mw) {
        padding: rem(10px) rem(15px);
      }
    }
    &__icon {
      width: rem(55px);
      height: rem(55px);
      border-radius: 50%;
      background: $secundario;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: rem(10px);
      svg {
        fill: blanco(1);
        width: rem(40px);
        max-height: rem(30px);
        // height: rem(40px);
      }
    }
    &__title {
      font-weight: 800;
      text-transform: uppercase;
      width: calc(100% - 65px);
      line-height: 1;
    }
  }
  &__hover {
    background: rgba($secundario,.9);
    backdrop-filter: blur(2px);
    @include posicion(absolute,-10px,-10px,-10px,-10px);
    padding: rem(30px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: blanco(1);
    transform: scale(1.2) translateY(10px);
    opacity: 0;
    @include transicion;
    user-select: none;
    text-align: center;
    &__icon {
      width: rem(35px);
      height: rem(35px);
      margin-bottom: rem(10px);
      svg {
        fill: currentColor;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }
    &__title {
      font-size: rem(22px);
      font-weight: 700;
      margin-bottom: rem(5px);
    }
    &__desc {
      font-size: rem(14px);
    }
    &:hover {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
    @include break(md-mw) {
      @include posicion(relative,0,0,0,0);
      opacity: 1;
      transform: none;
      text-align: left;
      padding: rem(20px) rem(15px);
      align-items: flex-start;
      &__icon,
      &__title {
        display: none;
      }
    }
  }
}
