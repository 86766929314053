.popup {
  &-overlay {
    // background: blanco(.1);
    backdrop-filter: blur(2px) grayscale(75%);
    animation: fade 200ms ease-in 1 forwards;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: rem(10px);
  }
  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: negro(.5);
    @include posicion(absolute,0,0,null,null);
    z-index: 1;
    cursor: pointer;
  }
  &__content {
    padding: rem(30px);
    background: blanco(1);
    max-width: 1000px;
    // border-radius: rem(10px);
    box-shadow: 0 0 30px negro(0.2), 0 0 5px negro(0.1);
    @include break(md-mw) {
      padding: rem(15px);
    }
  }
  &__image {
    position: relative;
    img {
      max-width: 100%;
    }
  }
}

@keyframes fade {
  from {
    background: negro(0);
  }
  to {
    background: negro(.6);
  }
}
