.crop {
  @include posicion(fixed, 0, 0, 0, 0);
  height: 100%;
  width: 100%;
  background: blanco(0.3);
  z-index: 100;
  backdrop-filter: blur(5px);
  padding: rem(30px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  &__container {
    max-width: rem(600px);
    margin: auto;
  }
  .reactEasyCrop_Container {
    position: relative;
    img {
      position: relative;
    }
  }
  &__footer {
    width: 100%;
    padding: rem(20px) 0;
    text-align: right;
  }
}
