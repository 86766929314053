.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: rem(20px);
  @include break(md-mw) {
    padding: rem(15px);
  }
  &__image {
    width: rem(150px);
    height: rem(150px);
    border: 10px solid lighten($secundario, 10%);
    border-radius: 50%;
    box-shadow: 0 3px 15px negro(0.15);
    margin-bottom: rem(30px);
    position: relative;
    // overflow: hidden;
    img {
      border-radius: 50%;
      display: block;
      width: 100%;
      height: 100%;
    }
    &__upload {
      @include posicion(absolute,null,rem(-5px), rem(-5px), null);
      width: rem(40px);
      height: rem(40px);
      border-radius: 50%;
      background: $terciario;
      color: blanco(1);
      display: flex;
      justify-content: center;
      align-items: center;
      @include transicion;
      overflow: hidden;
      &:hover {
        box-shadow: 0 0 10px rgba($terciario,.2);
        background: lighten($terciario, 10%);
      }
      input {
        @include posicion(absolute,0,0,0,null);
        opacity: 0;
        cursor: pointer;
        width: 400%;
      }
    }
    @include break(md-mw) {
      width: rem(100px);
      height: rem(100px);
    }
  }
  &__name,
  &__id,
  &__title {
    text-transform: uppercase;
    color: blanco(1);
    margin-bottom: rem(10px);
    font-weight: 800;
    text-align: center;
  }
  &__name {
    font-size: rem(18px);
  }
  &__id {
    font-size: rem(14px);
  }
  &__title {
    font-weight: 600;
    color: $terciario;
    display: inline-flex;
    padding: rem(10px) rem(15px);
    box-shadow: 0 3px 15px negro(0.15);
    border-radius: rem(30px);
    font-size: rem(12px);
  }
}
