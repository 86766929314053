.accordion {
  $t: &;
  &__hack {
    transform: translateY(calc(var(--header) * -1 - 20px));
  }
  &__button {
    font-size: rem(20px);
    padding: rem(15px);
    display: flex;
    justify-content: space-between;
    color: $secundario;
    border-bottom: 1px solid negro(0.1);
    border-left: 0 solid $primario;
    cursor: pointer;
    @include transicion;
    &:hover {
      opacity: 0.6;
    }
  }
  &__panel {
    border-bottom: 1px solid negro(0.1);
    padding: rem(15px) 0;
    line-height: 1.5;
    color: $color-parrafos;
  }
  &__item {
    &--active {
      #{$t}__button {
        border-left-width: rem(5px);
        path {
          fill: $primario;
        }
      }
    }
  }
}
