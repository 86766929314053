.futbol {
  &__fechas {
    &__header {
      padding: rem(10px);
      background: lighten($secundario, 75%);
      position: sticky;
      top: rem(100px);
      @include break(xl-mw) {
        top: rem(60px);
      }
    }
    &__matches {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    &__match {
      padding: rem(30px);
      display: flex;
      border: 1px solid lighten($secundario, 75%);
      border-top: 0;
      line-height: 1.5;
      color: $secundario;
      &:nth-of-type(even) {
        border-left: 0;
      }
      &__teams {
        width: calc(100% - 90px);
        padding-right: rem(30px);
      }
      &__team {
        display: flex;
        align-items: center;
        margin-bottom: rem(10px);
      }
      &__logo {
        width: rem(30px);
        height: rem(30px);
        display: block;
        margin-right: rem(10px);
        fill: lighten($secundario, 70%);
      }
      &__goals {
        padding-left: rem(10px);
        margin-left: auto;
      }
      &__status {
        width: rem(90px);
      }
    }
  }
}
