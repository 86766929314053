.statusbar {
  $t: &;
  padding: rem(50px) 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__container {
    width: 75%;
    margin: rem(50px) auto rem(130px);
    position: relative;
    @include break(md-mw) {
      width: 85%;
      margin: rem(100px) rem(15px) rem(130px);
    }
  }
  &__data {
    font-size: rem(14px);
    color: negro(0.7);
    padding: rem(5px) rem(10px);
    background: rgba($terciario,.1);
    &--success {
      background-color: $terciario;
      color: blanco(1);
    }
  }
  &__bar {
    height: rem(40px);
    background: negro(0.05);
    border-radius: rem(5px);
    overflow: hidden;
    display: flex;
    align-items: center;
    &__item {
      height: 100%;
      position: relative;
      &::after {
        content: attr("data-label");
        display: block;
      }
    }
  }
  &__info {
    @include posicion(absolute, 0, 0, null, 0);
    &__item {
      font-size: rem(18px);
      font-weight: 800;
      color: $secundario;
      text-align: center;
      @include posicion(absolute, 0, null, null, null);
      transform: translateX(-50%);
      @include break(md-mw) {
        font-size: rem(12px);
      }
      &--1 {
        left: 29.3%;
      }
      &--2 {
        left: 88.2353%;
      }
      &::before {
        content: "";
        display: block;
        width: 2px;
        height: rem(50px);
        background: $secundario;
        margin: auto;
      }
      span {
        font-weight: normal;
        display: block;
        white-space: nowrap;
        font-size: rem(14px);
      }
    }
  }
  &__now {
    @include posicion(absolute, rem(-20px), null, null, 0);
    transform: translateX(-50%);
    text-align: center;
    font-size: rem(14px);
    &__total {
      position: relative;
      &::before {
        content: attr(data-value);
        @include posicion(absolute, null, null, 100%, null);
        transform: translateX(-50%);
      }
      &::after {
        content: "";
        display: block;
        width: 0;
        border-right: 2px dashed negro(0.5);
        height: rem(115px);
        margin: auto;
      }
    }
  }
  &__label {
    font-size: rem(20px);
    color: blanco(1);
    font-weight: 800;
    display: inline-flex;
    white-space: nowrap;
    padding: rem(5px) rem(10px);
    @include posicion(absolute, calc(100% + 55px), null, null, null);
    transform: translateX(-50%);
    @include break(md-mw) {
      font-size: 12px;
      font-weight: 600;
      white-space: inherit;
      text-align: center;
    }
  }
}
