.dashboard {
  padding-top: rem(100px);
  @include break(xl-mw) {
    padding-top: var(--headerDash);
  }
  &__container {
    display: flex;
    background: gris(98);
    min-height: 70vh;
    position: relative;
    top: 0;
    @include break(md-mw) {
      flex-direction: column;
    }
  }
  &__side {
    height: calc(100vh - var(--headerDash));
    width: var(--side-menu);
    background: $secundario;
    padding-bottom: rem(30px);
    @include break(md) {
      position: sticky;
      top: var(--headerDash);
      overflow-y: auto;
    }
    @include break(md-mw) {
      width: 100%;
      height: auto;
      padding-bottom: 0;
    }
  }
  &__menu {
    $t: &;
    position: relative;
    &__open {
      @include posicion(absolute, 0, 0, null, 0);
      height: rem(40px);
      width: 100%;
      z-index: 1;
      opacity: 0;
      &:checked {
        ~ #{$t}__list {
          max-height: calc(70px * 10);
        }
        ~ #{$t}__burger {
          background: $terciario;
        }
      }
    }
    &__burger {
      display: flex;
      justify-content: space-between;
      padding: rem(10px);
      color: blanco(1);
      background: blanco(0.05);
      @include transicion;
    }
    @include break(md) {
      &__open,
      &__burger {
        display: none;
      }
    }
    &__list {
      @include break(md-mw) {
        overflow: hidden;
        max-height: 0;
        @include transicion;
      }
    }
    &__item {
      &:first-of-type {
        #{$t}__link {
          border-top: 1px solid blanco(0.1);
        }
      }
    }
    &__link {
      display: flex;
      align-items: flex-start;
      color: blanco(1);
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 800;
      font-size: rem(18px);
      padding: rem(10px);
      @include transicion;
      border-bottom: 1px solid blanco(0.2);
      &.active {
        background: $primario;
      }
      &:hover:not(.active) {
        background: blanco(0.1);
      }
      svg {
        width: rem(30px);
        height: rem(30px);
        margin-right: rem(10px);
        fill: blanco(1);
      }
      span {
        width: calc(100% - 40px);
      }
      em {
        display: block;
        font-style: normal;
        text-transform: none;
        font-weight: normal;
        font-size: rem(14px);
      }
    }
  }
  &__body {
    padding: rem(20px);
    width: calc(100% - var(--side-menu));
    max-width: rem(1100px);
    margin: 0 auto;
    @include break(md-mw) {
      padding: 0;
      width: 100%;
    }
  }
  &__content {
    padding: rem(30px);
    background: blanco(1);
    box-shadow: 0 0 20px negro(0.1);
    @include break(md-mw) {
      padding: rem(15px);
    }
  }
  h1 {
    margin-top: 0;
    font-weight: 800;
  }
  &__stats {
    position: relative;
    padding: 0 rem(10px);
    padding-top: rem(100px);
    margin-bottom: rem(30px);
    &__bg {
      @include posicion(absolute, 0, 0, 20%, 0);
    }
    &__container {
      position: relative;
      display: flex;
      background: blanco(0.9);
      backdrop-filter: blur(6px);
      border-radius: rem(30px);
      padding: rem(30px);
      box-shadow: 0 0 30px negro(0.1);
      @include break(xl-mw) {
        flex-wrap: wrap;
      }
    }
    &__item {
      display: flex;
      padding: 0 rem(15px);
      flex-direction: column;
      align-items: center;
      color: $secundario;
      text-align: center;
      margin: rem(10px) 0;
      flex: 1;
      &--h {
        width: 100%;
        max-width: rem(350px);
        text-align: left;
        flex-direction: row;
        justify-content: space-between;
        flex: auto;
        border-left: 1px solid negro(0.1);
        @include break(xl-mw) {
          border: none;
          padding: rem(30px) rem(15px);
          flex-direction: column;
          text-align: center;
        }
      }
    }
    // &__icon {
    //   width: rem(70px);
    //   height: rem(70px);
    //   border-radius: 50%;
    //   background: blanco(1);
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   margin-bottom: rem(20px);
    //   box-shadow: 0 0 10px negro(0.1);
    //   svg {
    //     fill: currentColor;
    //   }
    //   &--img {
    //     background: transparent;
    //     box-shadow: none;
    //     img {
    //       width: calc(100% + 10px);
    //       margin-left: rem(-5px);
    //       margin-right: rem(-5px);
    //     }
    //   }
    //   &--big {
    //     width: rem(90px);
    //     height: rem(90px);
    //     margin-right: rem(10px);
    //   }
    // }
    &__info {
      width: calc(100% - 90px);
    }
    &__value {
      font-weight: 500;
      font-size: rem(28px);
      line-height: 1.5;
    }
    &__label {
      font-size: rem(18px);
      font-weight: 500;
      text-transform: uppercase;
      span {
        display: inline-flex;
        padding: rem(2px) rem(5px);
      }
    }
    &__desc {
      font-size: rem(12px);
      opacity: .6;
    }
  }
  &__history {
    margin-bottom: rem(50px);
    &__box {
      position: relative;
      width: 45%;
      padding: rem(20px) rem(50px);
      display: flex;
      align-items: center;
      @include break(xl-mw) {
        width: 100%;
      }
      @include break(md-mw) {
        padding: rem(20px);
      }
      &__content {
        position: relative;
      }
    }
    &__table {
      background: negro(0.05);
      table {
        width: 100%;
        border-collapse: collapse;
      }
      th {
        text-transform: uppercase;
      }
      td, th {
        padding: rem(15px);
        // font-size: rem(16px);
        border: 1px solid negro(0.1);
        text-align: right;
        @include break(md-mw) {
          padding: rem(10px);
        }
      }
      td {
        color: rgba($secundario, 0.7);
      }
      tr:first-child {
        th {
          border-top: 0;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
      tr:last-child {
        td {
          border-bottom: 0;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
            font-size: rem(18px);
            color: $primario;
          }
        }
      }
      @include break(xl-mw) {
        thead {
          display: none;
        }
        td {
          display: flex;
          // text-align: left;
          &::before {
            content: attr(data-td);
            display: inline-block;
            margin-right: auto;
            padding-right: 10px;
            color: $secundario;
          }
        }
      }
    }
  }
}
