.slideUp {
  animation: slideUp 300ms ease-in 1 forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0);
  }
}

.fadeIn {
  animation: fadeIn 200ms ease-in 1 forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}