.expertos {
  &__banner {
    position: relative;
    height: rem(400px);
    background: $secundario;
    &__bg {
      overflow: hidden;
      @include posicion(absolute, 0, 0, 0, 0);
    }
    &__container {
      height: 100%;
      display: flex;
      align-items: flex-end;
      position: relative;
    }
    &__info {
      max-width: rem(400px);
      padding: calc(var(--header) / 2) 0;
      
    }
  }
  &__info {
    text-align: center;
    margin-bottom: rem(50px);
    h2 {
      margin-bottom: rem(15px);
    }
    &--section {
      padding: rem(70px);
      background: negro(.1);
      margin: rem(50px) 0;
      @include break(md-mw) {
        padding: rem(30px) rem(15px);
      }
      h2 {
        margin-top: 0;
      }
      p {
        margin-bottom: rem(15px);
        span {
          font-weight: 600;
          color: $primario;
        }
      }
    }
  }
}
