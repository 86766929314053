.tiendas {
  &__checks {
    background: $primario;
    padding: rem(30px) 0;
    margin-bottom: rem(50px);
    @include break(md-mw) {
      display: none;
    }
    .checks {
      justify-content: center;
      &__item {
        width: 100%;
        max-width: rem(300px);
      }
    }
  }
  &__select {
    padding-top: rem(30px);
    @include break(md) {
      display: none;
    }
  }
}