.login {
  position: relative;
  padding-top: var(--header);
  min-height: 100%;
  overflow-x: hidden;
  &__bg {
    background: blanco(1);
    @include posicion(absolute,0,0,0,0);
    overflow: hidden;
    img {
      opacity: .6;
    }
  }
  &__container {
    position: relative;
    min-height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &__form {
    min-height: 100%;
    padding: rem(50px) rem(30px);
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 100vw;
      height: 100%;
      @include posicion(absolute,0,null,0,0);
      background: rgba($terciario,.8);
    }
    &__content {
      position: relative;
      z-index: 1;
    }
    h2, p {
      text-align: left;
    }
  }
  fieldset {
    border: 0;
    max-width: 400px;
  }
  &__footer {
    position: relative;
    z-index: 1;
    .link-externo {
      color: $secundario;
    }
    p {
      text-transform: uppercase;
      font-weight: 800;
      color: $secundario;
    }
    &__item {
      padding: rem(20px) 0;
      border-top: 1px dashed $secundario;
    }
  }

}