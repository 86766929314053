.filtro-categorias {
  background: $terciario;
  &__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: rem(90px);
    padding: 0 rem(10px);
    font-size: rem(14px);
    text-decoration: none;
    @include transicion;
    color: $secundario;
    svg {
      @include transicion;
      fill: currentColor;
      width: rem(40px);
      margin-bottom: rem(5px);
      height: rem(40px);
    }
    &:hover {
      // color: blanco(1);
      filter: invert(1) brightness(150%);
    }
    // width: rem(90px);
  }
}