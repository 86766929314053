.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: rem(50px);
  background: $secundario;
  @include break(md-mw) {
    margin-bottom: 0;
  }
  &__container {
    height: 65vh;
    .swiper-wrapper {
      height: 100%;
    }
    @include break(md-mw) {
      height: 600px;
    }
  }
  &__item {
    padding: rem(140px) 0;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: $secundario;
  }
  &__bg {
    @include posicion(absolute, 0, 0, 0, 0);
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
  &__content {
    text-align: center;
    position: relative;
    z-index: 1;
  }
  &__title,
  &__desc {
    color: blanco(1);
  }
  &__desc {
    max-width: rem(850px);
    margin: auto;
    font-size: rem(20px);
    @include break(lg-mw) {
      font-size: rem(16px);
    }
  }
  &__bottom {
    height: rem(25px);
    overflow: hidden;
    background: $secundario;
    &__bg {
      width: 100%;
      height: 100%;
      opacity: .5;
    }
  }
}
