.responses {
  position: relative;
  min-height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: rem(70px);
    max-width: rem(650px);
    margin: rem(10px);
    background: blanco(1);
    box-shadow: 0 0 20px negro(.1), 0 0 5px negro(.05);
    p {
      margin-bottom: rem(30px);
    }
    @include break(md-mw) {
      padding: rem(30px);
    }
  }
}