.header {
  $t: &;
  @include posicion(fixed, 0, 0, null, 0);
  z-index: 99;
  @include transicion;
  @include break(xl-mw) {
    background: $secundario;
    height: var(--header)
  }
  &__top {
    @include transicion;
    height: rem(50px);
    background: lighten($secundario, 5%);
    overflow: hidden;
    .container {
      justify-content: flex-end;
    }
    .btn {
      margin-bottom: 0;
      flex: 1;
      font-weight: normal;
    }
    @include break(xl-mw) {
      display: none;
    }
  }
  &__actions {
    display: inline-flex;
    .btn {
      font-size: rem(13px);
    }
  }
  &__container {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &__bottom {
    height: rem(90px);
    @include transicion;
    @include break(xl-mw) {
      height: var(--header);
    }
  }
  &__item {
    &--logo {
      height: rem(140px);
      margin-top: rem(-50px);
      @include transicion;
      img {
        display: block;
        height: 100%;
        @include transicion;
      }
      @include break(xl-mw) {
        height: rem(70px);
        margin-top: 0;
        margin-bottom: rem(-20px);
      }
    }
    &--menu {
      margin-left: auto;
      height: 100%;
      @include break(xl-mw) {
        display: none;
      }
    }
  }
  &__burger {
    display: none;
    @include break(xl-mw) {
      display: block;
      margin-left: auto;
    }
    svg,
    path {
      fill: blanco(1);
    }
  }
  &--pages,
  &--dashboard {
    background: $secundario;
  }
  &--active {
    @include break(lg) {
      background: $secundario;
      #{$t}__bottom {
        height: rem(60px);
      }
      #{$t}__item {
        &--logo {
          height: rem(100px);
        }
      }
    }
    @include break(lg-mw) {
      #{$t}__item {
        &--logo {
          height: rem(50px);
          margin-bottom: 0;
        }
      }
    }
  }
  &--dashboard {
    // --header: 100px;
    #{$t}__top,
    #{$t}__bottom {
      padding-left: var(--side-menu);
      @include break(md-mw) {
        padding-left: 0;
      }
    }
    #{$t}__bottom {
      height: rem(50px);
    }
  }
  &__logo {
    @include posicion(absolute, 0, null, 0, 0);
    z-index: 1;
    width: 100%;
    max-width: var(--side-menu);
    display: flex;
    justify-content: center;
    background: $secundario;
    // padding: rem(10px);
    img {
      height: 100%;
    }
    @include break(md-mw) {
      width: rem(120px);
      left: rem(10px);
    }
  }
  &--menu-active {
    box-shadow: 0 0 rem(10px) negro(.2);
  }
}
