.beneficios {
  margin-bottom: rem(100px);
  &__calugas {
    &__item {
      padding: rem(60px) rem(40px);
      height: calc(100% - 30px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: rem(30px);
    }
    &__title {
      margin: 0;
    }
    &__desc, p {
      margin-bottom: 0;
      font-size: rem(20px);
    }
  }
  &__pasos {
    &__item {
      // height: 100%;
      text-align: center;
      padding-bottom: 100%;
      border-radius: 50%;
      position: relative;
      margin: auto;
      margin-bottom: rem(30px);
    }
    &__content {
      @include posicion(absolute,0,0,0,0);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: rem(50px) rem(30px);
    }
    &__n {
      font-size: rem(150px);
      font-weight: 600;
      color: blanco(1);
      line-height: 1;
      @include break(md-mw) {
        font-size: rem(100px);
      }
    }
    &__desc {
      color: blanco(1);
      font-size: rem(18px);
      span {
        font-weight: 600;
        display: block;
      }
      a {
        text-decoration: none;
      }
    }
  }
}