$breakpoints: (
  sm-mw: "max-width:543px",
  sm: "min-width:544px",
  md-mw: "max-width:767px",
  md: "min-width:768px",
  lg-mw: "max-width:991px",
  lg: "min-width:992px",
  xl-mw: "max-width:1199px",
  xl: "min-width:1200px",
  xxl-mw: "max-width:1399px",
  xxl: "min-width:1400px"
);

@mixin break($size) {
  @media (map-get($breakpoints, $size)) {
    @content;
  }
}
