.clientes {
  $t: &;
  padding-bottom: rem(100px);
  &__container {
    padding: rem(50px) rem(15px);
    @include break(md-mw) {
      padding: rem(15px);
    }
  }
  &__item {
    $ti: &;
    display: flex;
    margin-bottom: rem(30px);
    // background: $terciario;
    @include break(xl-mw) {
      flex-direction: column;
      &--left {
        width: 100%;
      }
    }
    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem(70px) rem(40px);
      &--left {
        flex-direction: column;
        width: 65%;
        @include break(xl-mw) {
          width: 100%;
        }
      }
      @include break(md-mw) {
        flex-direction: column;
        text-align: center;
        padding: rem(30px);
      }
    }
    &__icon {
      margin-right: rem(15px);
      @include break(md-mw) {
        margin-bottom: rem(20px);
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(100px);
        height: rem(100px);
        border-radius: 50%;
        background: blanco(1);
        svg {
          width: 60%;
          height: 60%;
          path {
            fill: $terciario;
          }
        }
      }
    }
    &__parraf {
      font-size: rem(22px);
      margin-bottom: rem(40px);
      span {
        color: negro(1);
        font-weight: 600;
      }
    }
    &--preferente {
      background: $secundario;
      #{$ti}__parraf {
        span {
          color: $primario;
        }
      }
      #{$ti}__icon {
        span {
          svg {
            path {
              fill: $secundario;
            }
          }
        }
      }
    }
    &--bronce {
      background: $bronce;
      #{$ti}__parraf {
        span {
          color: $primario;
        }
      }
      #{$ti}__icon {
        span {
          svg {
            path {
              fill: $bronce;
            }
          }
        }
      }
    }
    &__box {
      position: relative;
      text-align: center;
      padding: rem(40px);
      text-decoration: none;
      width: 100%;
      flex: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include break(md-mw) {
        height: auto;
        margin-bottom: rem(15px);
        padding: rem(20px);
      }
      &--side {
        @include break(xl) {
          max-width: rem(550px);
        }
      }
      &--aside {
        height: 100%;
      }
    }
    &__bg {
      @include posicion(absolute, 0, 0, 0, 0);
      width: 100%;
      overflow: hidden;
      img {
        opacity: 0.2;
        @include transicion;
      }
    }
    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      // height: 100%;
      position: relative;
      @include break(md-mw) {
        height: auto;
      }
    }
    &__icon {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: blanco(1);
    }
    &__title {
      @include break(lg-mw) {
        font-size: rem(35px);
      }
    }
    &__item {
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
    &__btn {
      margin-top: auto;
    }
  }
  &__progress {
    $tp: &;
    position: relative;
    width: 100%;
    margin-top: rem(30px);
    padding-bottom: rem(30px);
    // padding: rem(30px) 0;
    @include break(md-mw) {
      display: none;
    }
    &__barra {
      width: calc(100% - 10px);
      margin-left: rem(10px);
      position: relative;
      height: rem(10px);
      border-radius: rem(5px);
      background: blanco(1);
      overflow: hidden;
      &::before {
        content: "";
        display: block;
        height: 100%;
        border-radius: rem(5px);
        // background: $preferente;
        width: 32.5%;
        margin-left: 13%;
        // @include break(xl-mw) {
        //   margin-left: 43%;
        //   width: 52%;
        // }
      }
    }
    &__point {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include posicion(absolute, -5px, null, null, null);
      color: blanco(1);
      // font-size: rem(12px);
      font-weight: 600;
      &:nth-child(1) {
        left: 0;
      }
      &:nth-child(2) {
        left: 10%;
      }
      &:nth-child(3) {
        left: 40%;
        right: auto;
      }
      span {
        // background: blanco(1);
        background: $secundario;
        border: 2px solid blanco(1);
        display: block;
        width: rem(20px);
        height: rem(20px);
        border-radius: 50%;
        margin-bottom: rem(5px);
        &[data-active="true"] {
          transform: scale(1.5);
          background: $preferente;
        }
      }
    }
    &[data-type="preferente"] {
      #{$tp}__barra {
        &::before {
          width: calc(100% - 35px);
          background: $experto;
          margin-left: 45%;
          // @include break(xl-mw) {
          //   margin-left: 45%;
          // }
        }
      }
      #{$tp}__point {
        &:nth-child(2) {
          left: 10%;
        }
        &:nth-child(3) {
          left: 40%;
          right: auto;
        }
        span {
          &[data-active="true"] {
            background: $experto;
          }
        }
      }
    }
  }
}
