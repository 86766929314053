.descuento {
  position: relative;
  background: blanco(1);
  padding-bottom: 50px;
  &__bg {
    @include posicion(absolute, 0, 0, 0, null);
    width: 100%;
    max-width: rem(600px);
    img {
      object-fit: contain;
      object-position: bottom right;
      opacity: .2;
    }
  }
  &__content {
    padding: rem(50px);
    position: relative;
    @include break(md-mw) {
      padding: rem(30px);
    }
    @include break(sm-mw) {
      padding: 0;
    }
    &__bg {
      @include posicion(absolute, 0, 0, 0);
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
  &__info {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    position: relative;
    &__icon {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include posicion(absolute, rem(-40px), rem(-40px), null, null);
      background: $secundario;
      box-shadow: 0 3px 6px negro(0.15);
      @include break(md-mw) {
        width: 100px;
        height: 100px;
        @include posicion(absolute, rem(-20px), rem(-20px), null, null);
        svg {
          width: 70%;
          height: 70%;
        }
      }
      @include break(md-mw) {
        display: none;
      }
    }
    &__content {
      max-width: 500px;
      background: $secundario;
      padding: rem(40px);
      border-top: 20px solid $primario;
    }
  }
  &__title {
    margin-top: 0;
  }
  &__desc {
    strong {
      color: $terciario;
    }
  }
  &__footer {
    padding-top: rem(10px);
    display: flex;
    align-items: center;
    &__btn {
      margin-right: rem(20px);
    }
    &__desc {
      font-size: rem(14px);
      margin-bottom: 0;
    }
    @include break(md-mw) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      &__btn {
        margin-bottom: rem(10px);
      }
    }
  }
}
