.articles {
  $t: &;
  &__item {
    display: flex;
    margin-bottom: rem(20px);
    border: 1px solid rgba($secundario, 0.1);
    @include transicion;
    text-decoration: none;
    @include break(lg-mw) {
      flex-direction: column;
    }
  }
  &__image,
  &__content {
    flex: 1;
  }
  &__image {
    position: relative;
    aspect-ratio: 9/16;
    overflow: hidden;
    @include break(lg-mw) {
      aspect-ratio: 16/9;
    }
    img {
      @include posicion(absolute, 0, 0, 0, 0);
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include transicion;
    }
  }
  &__content {
    padding: rem(30px);
    background: blanco(1);
    display: flex;
    flex-direction: column;
    min-height: 100%;
    @include break(sm-mw) {
      padding: rem(15px);
    }
  }
  &__title {
    margin-top: 0;
    padding-bottom: 0.75em;
    border-bottom: 1px solid negro(0.3);
    @include transicion;
    @include break(md-mw) {
      border: 0;
    }
  }
  &__excerpt {
    min-height: 50%;
    @include transicion;
    margin-bottom: rem(20px);
    @include break(md-mw) {
      display: none;
    }
  }
  &__footer {
    margin-top: auto;
  }
  &__item {
    &:hover {
      img {
        transform: scale(1.1);
      }
      #{$t}__title {
        color: $primario;
      }
      #{$t}__excerpt {
        opacity: 0.6;
      }
    }
    &--wide {
      @include break(lg) {
        height: calc(100% - #{rem(30px)});
        padding-bottom: rem(30px);
      }
      #{$t}__image,
      #{$t}__content {
        flex: auto;
        // height: 100%;
      }
      #{$t}__image {
        aspect-ratio: 6/7;
        width: rem(300px);
        flex: auto;
        margin-bottom: rem(-30px);
        @include break(lg-mw) {
          width: 100%;
          aspect-ratio: 16/9;
          margin-bottom: rem(30px);
        }
      }
      #{$t}__content {
        width: calc(100% - #{rem(300px)});
        @include break(lg-mw) {
          width: 100%;
        }
      }
    }
  }
}
