.registro {
  position: relative;
  padding-top: calc(var(--header) + 20px);
  .section__subtitle {
    color: $secundario;
  }
  &__bg {
    background: blanco(1);
    @include posicion(absolute,0,0,0,0);
    overflow: hidden;
    img {
      opacity: .2;
    }
  }
  &__container {
    position: relative;
    min-height: 100%;
  }
  .checks {
    max-width: rem(1000px);
  }
}

.lista {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;

  &__item {
    display: flex;
    align-items: start;
    gap: 0.5rem;
  }
}