
.dark-mode-toggle{
    display: block;
    margin-bottom: 40px;

    .title--darkmode{
        color: #333333;
    }
    .darkmode--title--ligth{
        color: #fff;
    }
    .dark-mode-toggle__content{
        display: flex;
        min-width: 85px;
        button{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-size: 1rem;
            padding: 8px 4px;
            border-radius: 6px;
            max-width: 150px;
            height: 100%;
            cursor: pointer;
            border:none;
            background-image:none;
            background-color:transparent;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            &:hover{
                opacity: .8;
                background: #e2e2e2;
            }
            .svg-icon{
                fill: #202020;
                width: 30px;
                margin: 0 auto 10px auto;
            }
        }

        .dark-mode-toggle__text{
            display: block;
            width: 100%;
            font-size: 13px;
        }
    }

    h3{
        color: #fff;
        font-size: 16px;
    }
}




body.dark-mode{
    background: #333333;
    
    
    
    .banner{
        .banner__bg{
            img{
                filter: grayscale(1);
            }
        }
        .banner__title{
            color: #fff;
        }
    }
    .page{
        .content{
            p, ul{
                color: #fff;
                strong{
                    color: #fff;
                }
            }
        }
        
    }

    .footer{
        .footer__container{
            .footer__logo{
                filter: grayscale(1);
            }
        }
        .active{
            color: #8e857a;
        }

        .fixed-btns{
            .fixed-btns__item{
                .btn--primario{
                    background: #2a2a2a;
                    border: 1px solid #2a2a2a;
                }
                .btn--terciario{
                    background: #2a2a2a;
                    border: 1px solid #2a2a2a;
                }
            }
        }
    }
    #root{
        .App{
            .header{
                .header__bottom{
                    .header__container{
                        .header__item{
                            img{
                                filter: grayscale(1);
                            }
                        }
                    }
                }

                .header__top{
                    .header__container{
                        .header__actions{
                            .btn--primario{
                                background: #2a2a2a;
                                border: 1px solid #2a2a2a;
                            }
                        }
                    }
                }
            }
        }
    }
}

.letter-size--big,
.letter-size--sbig {
  transition: font-size 0.3s ease;
}

.letter-size--big{
    .page{
        .content{
            p, ul{
                font-size: 20px;
                line-height: 40px;
            }
        }
        
    }
}

.letter-size--sbig{
    .page{
        .content{
            p, ul{
                font-size: 25px;
                line-height: 45px;
            }
        }
        
    }
}

.active--effect{
    .dark-mode-toggle__content{
        button{
            svg{
                fill: #b8b8b8 !important;
            }
            .dark-mode-toggle__text{
                color: #b8b8b8 !important;
            }
        }
    }
}