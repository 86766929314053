.banner {
  // margin-top: var(--header);
  overflow: hidden;
  background: $secundario;
  position: relative;
  padding: calc(var(--header) / 2) 0;
  &__bg {
    @include posicion(absolute,0,0,0,0);
    
    img {
      opacity: .2;
    }
  }
  &__container {
    // height: 100%;
    // min-height: rem(200px);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__title {
    margin: 0;
  }
  .breadcrumbs {
    padding-top: rem(20px);
    color: blanco(1);
  }
}