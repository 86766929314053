.footer {
  // padding-bottom: rem(100px);
  background: $secundario;
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding: rem(30px) 0;
    @include break(md-mw) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }
  &__redes {
    padding: 0;
    margin-bottom: rem(20px);
    @include break(md-mw) {
      justify-content: center;
    }
  }
  &__menu {
    $t: &;
    margin-bottom: rem(15px);
    &__list {
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      padding-left: rem(10px);
      margin-left: rem(10px);
      border-left: 1px solid blanco(1);
      margin-bottom: rem(10px);
      &:first-of-type {
        border: 0;
        padding: 0;
        margin-left: 0;
      }
    }
    &__link {
      text-decoration: none;
      color: blanco(1);
      @include transicion;
      display: block;
      font-size: rem(14px);
      &:hover {
        opacity: 0.6;
      }
      &.active {
        color: $terciario;
      }
    }
    @include break(md-mw) {
      display: none;
    }
    &--second {
      #{$t}__item {
        border-color: blanco(0.5);
        @include break(md-mw) {
          border: 0;
          margin: 0;
        }
      }
      #{$t}__link {
        font-size: rem(12px);
        line-height: 1;
        @include break(md-mw) {
          padding: rem(10px);
          font-size: rem(14px);
        }
      }
      @include break(md-mw) {
        display: block;
        margin-bottom: rem(30px);
        #{$t}__list {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
      }
    }
  }
  &__left {
    text-align: right;
    @include break(md-mw) {
      text-align: center;
    }
  }
  &__logo {
    display: inline-block;
    width: rem(200px);
    margin-bottom: rem(30px);
    @include break(md-mw) {
      display: none;
    }
  }
  &__copy {
    font-size: rem(12px);
    color: blanco(1);
  }
  &__lata {
    color: blanco(0.7);
    font-size: rem(10px);
    position: relative;
    text-align: center;
    padding: rem(15px);
    background: negro(.2);
    @include break(md-mw) {
      padding-bottom: rem(160px);
    }
  }
}
