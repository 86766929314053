.error404 {
  position: relative;
  // padding-top: var(--headerDash);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__bg {
    background: $terciario;
    @include posicion(absolute,0,0,0,0);
    overflow: hidden;
    img {
      opacity: .2;
    }
  }
  &__content {
    position: relative;
    text-align: center;
  }
}