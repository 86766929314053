.rrss {
  display: flex;
  align-items: center;
  padding: 0 30px;
  &__link {
    margin: 0 5px;
    color: blanco(1);
    @include transicion;
    &:hover {
      opacity: .6;
    }
  }
}