@mixin item-link($full: false) {
  $t: &;
  &__item {
    display: flex;
    text-decoration: none;
    margin-bottom: rem(20px);
    align-items: flex-start;
  }
  &__image {
    width: rem(50px);
    height: rem(50px);
    margin-right: rem(10px);
    border-radius: 50%;
    overflow: hidden;
  }
  @if $full {
    &__content {
      width: calc(100% - #{rem(60px)});
    }
  }
  &__title {
    font-size: rem(16px);
    margin: 0;
    @include transicion;
  }
  &__meta {
    font-size: rem(12px);
    opacity: 0.6;
  }
  &__item {
    &:hover {
      #{$t}__title {
        color: $terciario;
      }
    }
  }
}

.sidebar {
  padding: 0 rem($gap);
  padding-top: rem(40px);
  > *:not(h4) {
    margin-bottom: rem(40px);
  }
  img {
    max-width: 100%;
    display: block;
  }
  &__title {
    font-size: rem(20px);
    text-transform: uppercase;
    font-weight: 800;
  }
  &__buscador {
    display: flex;
    input {
      width: 100%;
      border-radius: rem(30px);
      padding: rem(12px) rem(20px);
      padding-right: rem(50px);
      border: 1px solid negro(0.1);
      box-shadow: 0 0 rem(15px) negro(0.05);
      background: blanco(1);
      color: map-deep-get($_CONFIG, 'ui', 'colores', 'textos', 'inputs');
      @include transicion;
      appearance: none;
      height: rem(40px);
      &:focus {
        outline: 0;
      }
      &::placeholder {
        opacity: 0.6;
      }
    }
    &__button {
      appearance: none;
      background: $secundario;
      color: blanco(1);
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(40px);
      height: rem(40px);
      border-radius: 50%;
      border: 0;
      margin-left: rem(-40px);
      &:focus {
        outline: 0;
      }
    }
    // margin-bottom: rem(30px);
  }
  &__cats {
    // margin-bottom: rem(30px);
    &__link {
      display: flex;
      align-items: center;
      padding: rem(10px) 0;
      text-decoration: none;
      @include transicion;
      svg {
        @include transicion;
        margin-right: rem(10px);
      }
      path {
        fill: $terciario;
      }
      &:hover {
        opacity: 0.6;
        svg {
          transform: translateX(rem(5px));
        }
      }
    }
  }
  &__image {
    display: block;
  }
  &__temas {
    @include item-link(true);
  }
  &__preguntas {
    @include item-link();
  }
}
